import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { ApiService, ICategoryPreview, IProductPreview, IProductStore, StoreKind } from '../../services/api.service';
import { InputText } from 'primereact/inputtext';
import debounce from 'lodash.debounce';
import { Tooltip } from 'primereact/tooltip';
import { OverlayPanel } from 'primereact/overlaypanel';

interface ProductListProps {
    onSelected: (p?: IProductPreview) => void,
    updatedStores: IProductStore[],
    category: ICategoryPreview,
}

export const ProductList: React.FC<ProductListProps> = ({ onSelected, updatedStores, category }) => {
    const service = new ApiService();

    const [loading, setLoading] = React.useState(false)
    const [products, setProducts] = React.useState<IProductPreview[]>([])
    const [selectedItem, setSelectedItem] = React.useState<IProductPreview>()
    const [globalFilter, setGlobalFilter] = React.useState('')


    const kindColors: { kind: StoreKind, color: string }[] = [
        { kind: StoreKind.migros, color: '#e96017' },
        { kind: StoreKind.carrefoursa, color: '#004da0' },
        { kind: StoreKind.sok, color: '#d42c11' },
        { kind: StoreKind.a101, color: '#00abc7' },
        { kind: StoreKind.bim, color: '#ffffff' },
    ]


    const search = async (text: string) => {
        if (loading) return;
        setProducts([]);
        if (text.length > 0) {
            setLoading(true);
            const result = await service.searchProduct(category.id, text);
            setProducts(result);
            setLoading(false);
        }

    }

    const debouncedSearchHandler = React.useMemo(() => debounce(search, 1000), []);

    React.useEffect(() => {
        setLoading(true);
        setProducts([])
        service.searchProduct(category.id, '').then(data => {
            setProducts(data);
            setLoading(false);
        })

        return () => {
            debouncedSearchHandler.cancel();
        }
    }, [category]);


    React.useEffect(() => {
        if (updatedStores.length > 0) {
            const _products = [...products];
            const updated = _products.find(p => p.id === selectedItem?.id);
            if (updated) {
                updated.stores = updatedStores;
                setProducts(_products);
            }
        }
    }, [updatedStores])

    const dtRef = React.useRef<DataTable>(null);

    const onNameFilterChanged = (value: string) => {
        dtRef.current?.filter(value, 'name', 'custom');
        setGlobalFilter(value);
    }

    //onSelectedTrigger
    React.useEffect(() => {
        onSelected(selectedItem)
        if (selectedItem) {
            navigator.clipboard.writeText(selectedItem.name);
        }
    }, [selectedItem])




    const ImageBody = ({ product }: { product: IProductPreview }) => {

        const showImageOverlay = (event: any) => {
            overlayRef.current?.toggle(event, null);
        }

        const handleOnMouseEnter = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
            debouncedHadler(event);
        }

        const handleOnMouseLeave = () => {
            debouncedHadler.cancel();
        }

        const debouncedHadler = React.useMemo(() => debounce((event: any) => showImageOverlay(event), 500), []);

        const overlayRef = React.useRef<OverlayPanel>(null);

        return (
            <>
                <OverlayPanel ref={overlayRef} dismissable showCloseIcon>
                    <img src={product.image} className="w-[600px] h-[600px] object-contain" />
                </OverlayPanel>
                <img src={product.image} className="object-contain" onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
            </>
        )
    }


    return (
        <Card>
            <Tooltip target=".product-name" />

            <DataTable
                ref={dtRef}
                value={products}
                rowHover
                loading={loading}
                selectionMode="single"
                selection={selectedItem}
                onSelectionChange={e => setSelectedItem(e.value)}
                scrollable
                scrollHeight="80vh"
                dataKey="id"
                paginator
                paginatorTemplate="CurrentPageReport   PageLinks  RowsPerPageDropdown"
                currentPageReportTemplate="Toplam: {totalRecords}"
                rows={20}
                rowsPerPageOptions={[20, 50, 100]}
            >
                <Column field="image" header="" className="w-10 !p-1" body={(product: IProductPreview) => <ImageBody product={product} />}></Column>
                <Column
                    field="name"
                    header="ÜRÜN ADI"
                    className="!p-0 relative"
                    sortable
                    filter
                    filterElement={(
                        <InputText id="searchInput" className="w-full p-inputtext-sm" placeholder="Arama Yapın..." value={globalFilter} onChange={e => onNameFilterChanged(e.currentTarget.value)} />
                    )}
                    filterFunction={(value: string, filter: string) => {
                        return value.toLocaleLowerCase("tr-TR").includes(filter.toLocaleLowerCase("tr-TR"));
                    }}
                    body={(product: IProductPreview) => (
                        <>
                            <span className="px-2 font-normal">{product.name}</span>
                            <div className="product-name bg-primary-300 rounded absolute top-1 right-1 h-5 w-14 flex gap-1 p-1 justify-end" data-pr-tooltip={product.stores.map(a => a.kind.toLocaleUpperCase()).join(' | ')}>
                                {kindColors.filter(p => product.stores.some(s => s.kind === p.kind)).map(item => (
                                    <div key={item.kind} style={{ backgroundColor: item.color }} className="w-1.5 h-full flex-shrink-0"> </div>
                                ))}
                            </div>
                        </>
                    )}></Column>
            </DataTable>
        </Card>
    )
}

