import React from 'react';

interface IAuthContext {
    isAuthenticated: boolean;
    signIn: () => void;
    signOut: () => void;
}


const AuthContext = React.createContext<IAuthContext>(null as any);

export const AuthWrapper: React.FC = ({ children }) => {
    
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    const signIn = () => setIsAuthenticated(true);
    const signOut = () => setIsAuthenticated(false);

    return (
        <AuthContext.Provider value={{ isAuthenticated, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return React.useContext(AuthContext);
}