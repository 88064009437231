import React from 'react'
import { Dropdown } from 'primereact/dropdown';
import { ApiService, ICategoryPreview } from '../services/api.service';
import { SelectItem } from 'primereact/selectitem'
import { Card } from 'primereact/card';
import { Button } from "primereact/button";

interface CategoriesProps {
    type: 'horizontal',
    loading: boolean,
    onSelected: (tree: ICategoryPreview[], item: ICategoryPreview) => void,
}

export const Categories: React.FC<CategoriesProps> = (props) => {

    if (props.type === 'horizontal') {
        return <HorizontalCategories {...props} />
    }

    return (
        <></>
    )
}


export const HorizontalCategories: React.FC<CategoriesProps> = (props) => {

    const service = new ApiService();
    const [loading, setLoading] = React.useState(false)

    const Category = ({ disabled, showClear, isSub, value, categories, onSelected, onClear }: {
        disabled: boolean,
        isSub: boolean,
        showClear?: boolean,
        value?: ICategoryPreview,
        categories: ICategoryPreview[],
        onSelected: (item: ICategoryPreview) => void,
        onClear?: () => void
    }) => {

        const [selected, setSelected] = React.useState<ICategoryPreview | undefined>(value)

        const items: SelectItem[] = categories.map(cat => ({ label: cat.name, value: cat }));

        React.useEffect(() => {
            if (selected && value?.id !== selected.id) {
                onSelected(selected);
            }

        }, [selected])

        return (
            <div className="inline">
                <Dropdown className="p-inputtext-sm" scrollHeight="300px" showClear={showClear} disabled={disabled} value={selected} options={items} onChange={(e) => {
                    if (onClear && (e.originalEvent.target as HTMLElement).classList.contains('p-dropdown-clear-icon')) {
                        onClear()
                    }
                    setSelected(e.value)
                }} placeholder={isSub ? 'Alt Kategori Seçiniz' : 'Kategori Seçiniz'} />
            </div>
        )
    }

    interface ISection {
        selected?: ICategoryPreview,
        categories: ICategoryPreview[]
    }


    const [sections, setSections] = React.useState<ISection[]>([]);

    const loadSubCategories = async (sectionIndex: number, selected: ICategoryPreview) => {
        console.log({ sectionIndex, selected })
        setLoading(true);
        const section = sections[sectionIndex];
        section.selected = selected;
        const subCategories = await service.getSubCategories(selected.id);

        if (subCategories.length > 0) {
            setSections([
                ...sections.filter((_, i) => i <= sectionIndex),
                { categories: subCategories },
            ]);
        }
        setLoading(false);
    }

    React.useEffect(() => {
        if (sections.length === 0) {
            setLoading(true);
            service.getRootCategories().then(data => {
                setSections([{ categories: data }]);
                setLoading(false);
            });
        }
    }, []);


    const handleListClick = () => {
        const _sections = sections.filter(p => p.selected);
        const item = _sections[_sections.length - 1].selected;
        console.log("onSelected item => ", item);
        if (item) {
            props.onSelected(_sections.map(m => m.selected || null as any), item);
        }
    }

    const handleOnClear = (sectionIndex: number) => {
        console.log("onClear=> ", sectionIndex)
        const copy = { ...sections[sectionIndex] };
        setSections([
            ...sections.filter((_, i) => i < sectionIndex),
            { ...copy, selected: undefined }
        ])
    }

    return (
        <Card className="reset-padding p-2">
            <div className="flex items-center gap-2">
                {sections.map((section, index) => (
                    <Category
                        key={index}
                        disabled={loading}
                        showClear={index > 0}
                        isSub={index > 0}
                        value={section.selected}
                        categories={section.categories}
                        onSelected={selected => loadSubCategories(index, selected)}
                        onClear={() => handleOnClear(index)}
                    />
                ))}
                <Button label="Listele" icon="pi pi-check" loading={loading || props.loading} onClick={handleListClick} />
            </div>
        </Card>
    )
}

