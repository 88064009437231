import React from 'react'
import { useHistory, useLocation } from 'react-router';
import { useAuth } from '../contexts/auth.context';

const LoginPage = () => {

    const auth = useAuth();
    const location = useLocation();
    const history = useHistory();

    const signIn = (username: string, password: string, to: string) => {
        if (username === 'admin' && password === '18811923') {
            const json = JSON.stringify({ username, password });
            const base64 = btoa(json);

            localStorage.setItem('_auth', base64);

            auth.signIn();
            history.push(to);
        }
        else {
            alert('Giriş bilgileriniz hatalı!');
        }
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);

        const username = formData.get('username') as string;
        const password = formData.get('password') as string;
        let to = '/app/products';

        if (location.search) {
            const params = new URLSearchParams(location.search);
            to = params.get('redirect') || '/app/products';
        }

        signIn(username, password, to);

    }

    React.useEffect(() => {
        const base64 = localStorage.getItem('_auth');
        if (base64) {
            const json = atob(base64);
            const data = JSON.parse(json);
            signIn(data.username, data.password, '/app/products');
        }
    }, [])

    return (
        <main className="w-full h-full">
            <div className="bg-gray-100 w-4/12 h-96 mx-auto mt-20 shadow-lg rounded-lg p-10">
                <h1 className="text-center text-2xl font-bold">Giriş Yapınız.</h1>

                <form className="mt-10 space-y-6" onSubmit={onSubmit}>
                    <div className="flex flex-col">
                        <label className="text-gray-700 text-sm font-bold mb-1">Kullanıcı Adı</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="username" placeholder="Kullanıcı Adı" />
                    </div>
                    <div className="flex flex-col">
                        <label className="text-gray-700 text-sm font-bold mb-1">Şifre</label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="password" name="password" placeholder="Şifre" />
                    </div>
                    <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">Giriş Yap</button>
                </form>
            </div>
        </main>
    )
}

export default LoginPage
