import axios, { AxiosInstance } from "axios";

export enum StoreKind {
    migros = 'migros',
    carrefoursa = 'carrefoursa',
    a101 = 'a101',
    sok = 'sok',
    bim = 'bim',
}

export class ApiService {

    private readonly httpClient: AxiosInstance;

    constructor() {
        this.httpClient = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });
    }

    async getAll() {
        const { data } = await this.httpClient.get<IProductPreview[]>('/products');
        return data;
    }

    async searchProduct(categoryId: string, term: string): Promise<IProductPreview[]> {
        const params = new URLSearchParams();
        params.append("category", categoryId)
        params.append('term', term);
        const { data } = await this.httpClient.get<{ items: IProductPreview[] }>('/products/search', { params });

        if (data) {
            return data.items.map((item) => ({ ...item, stores: (item as any).stores as any[] })).filter(p => p.stores.length > 0).map(item => ({
                ...item,
                price: item.stores.sort((a, b) => a.price - b.price)[0]
            }))
        }

        return data;
    }

    async getProduct(productId: string) {
        const { data } = await this.httpClient.get<IProductDetail>(`/products/${productId}`);
        return data;
    }

    async getActiveStoreKinds() {
        const { data } = await this.httpClient.get<IStoreKind[]>(`/stores/active-kinds`);
        return data;
    }

    async getStoreProductsSuggestions(kind: StoreKind, term: string) {
        const params = new URLSearchParams();
        params.set('term', term);
        const response = await this.httpClient.get<IStoreProductSuggestion[]>(`/products/${kind}/suggestions`, { params });
        return response.data;
    }

    async updateProductStores(productId: string, stores: IProductStore[]) {
        const response = await this.httpClient.put<{ success: boolean, existings?: { kind: StoreKind, productId: string, productName: string }[] }>(`/products/${productId}/stores`, stores);
        return response.data;
    }

    async getRootCategories() {
        const response = await this.httpClient.get<ICategoryPreview[]>('/categories');
        return response.data;
    }
    async getSubCategories(parentId: string) {
        const response = await this.httpClient.get<ICategoryPreview[]>(`/categories/${parentId}/sub`);
        return response.data;
    }

    async getStores(filter: IStoreFilterDto) {
        const params = new URLSearchParams();
        Object.keys(filter)
            .filter(p => (filter as any)[p] !== "all")
            .forEach(key => params.append(key, (filter as any)[key]));
        const response = await this.httpClient.get<IStorePreview[]>('/stores', { params })
        return response.data;
    }

    async updateStore(id: string, data: IStoreUpdateDto) {
        await this.httpClient.put(`/stores/${id}`, data);

    }

    async getCities() {
        const response = await this.httpClient.get<IPlacePreview[]>('/stores/cities')
        const res = [
            { name: 'TÜM İLLER', id: 'all' } as any,
            ...response.data
        ] as IPlacePreview[];

        return res;
        ;

    }
    async getCityTowns(cityId: string) {
        const response = await this.httpClient.get<IPlacePreview[]>(`/stores/cities/${cityId}`);
        return [
            { name: 'TÜM İLÇELER', id: 'all' } as any,
            ...response.data
        ] as IPlacePreview[];
    }

}

export type ProductStoreType = 'same' | 'equivalent';

export interface IStoreKind {
    id: string,
    name: string,
    logo: string
}

export interface IProductStore {
    kind: StoreKind,
    key: string,
    name: string,
    price: number,
    weight?: number,
    image: string,
    type: ProductStoreType
}
export interface IProductPreview {
    id: string,
    name: string,
    image: string,
    stores: IProductStore[]
}
export interface IProductDetail {
    id: string,
    name: string,
    image: string,
    detail: string,
    lastUpdate: number,
    stores: IProductStore[]
}

export interface IStoreProductSuggestion {
    id: string,
    kind: StoreKind,
    image: string,
    key: string,
    name: string,
    price: number
}

export interface ICategoryPreview {
    id: string,
    name: string
}

export interface IStoreFilterDto {
    kind?: string;
    city?: string;
    town?: string;
}

export interface IStorePreview {
    id: string,
    kind: StoreKind,
    group: string,
    name: string,
    lat: number,
    lng: number,
    address: string,
    city: string,
    town: string
}

export interface IStoreUpdateDto {
    name: string;
    address: string;
    group: string;
}

export interface IPlacePreview {
    id: string,
    name: string
}