import React from 'react'
import { Card } from 'primereact/card'
import { DataTable } from 'primereact/datatable'
import { ApiService, IPlacePreview, IStoreKind, IStorePreview, StoreKind } from '../services/api.service'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { SelectItem } from 'primereact/selectitem'

export default function StoresPage() {

    const [loading, setLoading] = React.useState(false)
    const [original, setOriginal] = React.useState<IStorePreview[]>([]);
    const [data, setData] = React.useState<IStorePreview[]>([]);

    const [initData, setInitData] = React.useState<{ kinds: IStoreKind[], cities: IPlacePreview[], towns: IPlacePreview[] }>()

    const [filterKind, setFilterKind] = React.useState('');
    const [filterCity, setFilterCity] = React.useState('')
    const [filterTown, setFilterTown] = React.useState('')


    const [groups, setGroups] = React.useState<SelectItem[]>([]);


    const service = new ApiService();

    React.useEffect(() => {
        if (!initData) {
            Promise.all([
                service.getActiveStoreKinds(),
                service.getCities(),
            ])
                .then(results => {
                    setInitData({
                        kinds: results[0],
                        cities: results[1],
                        towns: []
                    });
                    setFilterKind(results[0][0].id);
                    setFilterCity(results[1][0].id)

                })
        }
    }, [])

    React.useEffect(() => {
        if (filterCity && filterCity !== "all") {
            setFilterTown('');
            service.getCityTowns(filterCity).then(towns => {
                setInitData({
                    ...initData || { cities: [], kinds: [] },
                    towns: towns
                })
                setFilterTown(towns[0].id)
            })
        }
    }, [filterCity])


    const onEditorValueChange = (props: any, value: any) => {
        let updated = [...props.value];
        updated[props.rowIndex][props.field] = value;
        setData(updated);
    }

    const onRowEditInit = (event: any) => {
        const org = { ...data[data.findIndex(p => p.id === event.data.id)] };
        setOriginal([...original.filter(p => p.id != org.id), org]);
    }

    const onRowEditCancel = (event: { data: IStorePreview }) => {
        const i = data.findIndex(p => p.id === event.data.id);
        const updated = [...data];
        updated[i] = original.find(p => p.id === event.data.id) || {} as any;
        setData(updated);
        setOriginal([...original.filter(p => p.id !== event.data.id)]);

    }

    const inputTextEditor = (props: any, field: string) => {
        return <InputText className="p-inputtext-sm w-full" type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.target.value)} />;
    }

    const nameEditor = (props: any) => {
        return inputTextEditor(props, 'name');
    }

    const addressEditor = (props: any) => {
        return inputTextEditor(props, 'address');
    }

    const groupEditor = (props: any) => (
        <Dropdown
            options={groups}
            className="p-inputtext-sm w-full"
            value={props.rowData['group']}
            onChange={(e) => onEditorValueChange(props, e.value)}
        />
    )

    const onRowEditSave = async (event: { data: IStorePreview }) => {
        const item = event.data;
        (item as any).saving = true;
        await service.updateStore(item.id, { name: item.name, address: item.address, group: item.group, });
        (item as any).saving = false;
        setData([...data]);
    }

    const renderBody = (item: IStorePreview, key: keyof IStorePreview) => {
        if ((item as any).saving === true) {
            return (
                <Skeleton className="w-full" height="2rem" />
            )
        }

        if (key === 'kind') {

            return item[key] === StoreKind.a101 ? "A-101" :
                item[key] === StoreKind.bim ? "BİM" :
                    item[key] === StoreKind.carrefoursa ? "CARREFOURSA" :
                        item[key] === StoreKind.migros ? "MİGROS" :
                            item[key] === StoreKind.sok ? "ŞOK" : "<TANIMSIZ>";


        }
        else if (key === "group") {
            return groups.find(p => p.value === item[key])?.label;
        }

        return item[key];
    }

    const onList = async () => {
        setLoading(true);

        let city: string = '';
        let town: string = ''


        if (filterCity == "all") {
            city = "";
        }
        else {
            if (initData) {
                const cityItem = initData.cities.find(p => p.id == filterCity);
                if (cityItem) {
                    city = cityItem.name;
                }
            }
        }

        if (filterTown == "all") {
            town = "";
        }
        else {
            if (initData) {
                const townItem = initData.towns.find(p => p.id == filterTown);
                if (townItem) {
                    town = townItem.name;
                }
            }
        }


        const data = await service.getStores({ kind: filterKind, city, town });
        if (filterKind) {
            setGroups([]);
            if (filterKind === StoreKind.migros) {
                setGroups([
                    { label: 'Standart', value: 'Standart' },
                    { label: 'MJET', value: 'mjet' },
                    { label: 'M', value: 'm' },
                    { label: 'MM', value: 'mm' },
                    { label: 'MMM', value: 'mmm' },
                    { label: '5M', value: '5m' },
                ])
            }
            else if (filterKind === StoreKind.carrefoursa) {
                setGroups([
                    { label: 'Standart', value: 'Standart' },
                    { label: 'Süpermarket', value: 'Süpermarket' },
                ])
            }
            else {
                setGroups([
                    { label: 'Standart', value: 'Standart' },
                ])
            }
        }
        setData(data);
        setLoading(false);
    }


    return (
        <div className="space-y-2">
            <Card className="reset-padding p-2">
                <div className="flex items-center gap-2">
                    {initData && (
                        <>
                            <span className="p-float-label ">
                                <Dropdown
                                    inputId="dp_kind"
                                    scrollHeight="300px"
                                    value={filterKind}
                                    onChange={e => setFilterKind(e.value)}
                                    options={initData.kinds.map(item => ({ label: item.name.toLocaleUpperCase("tr-TR"), value: item.id }))}
                                    className="p-inputtext-sm w-40" />
                                <label htmlFor="dp_kind">MARKET</label>
                            </span>
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="dp_city"
                                    value={filterCity}
                                    filter
                                    scrollHeight="300px"
                                    onChange={e => setFilterCity(e.value)}
                                    options={initData.cities.map(item => ({ label: item.name, value: item.id }))}
                                    className="p-inputtext-sm w-48" />

                                <label htmlFor="dp_city">İL</label>
                            </span>
                            <span className="p-float-label">
                                <Dropdown
                                    inputId="dp_town"
                                    value={filterTown}
                                    filter
                                    scrollHeight="300px"
                                    onChange={e => setFilterTown(e.value)}
                                    options={initData.towns.map(item => ({ label: item.name, value: item.id }))}
                                    className="p-inputtext-sm w-52" />
                                <label htmlFor="dp_town">İLÇE</label>
                            </span>
                            <Button label="Listele" icon="pi pi-check" loading={loading} onClick={onList} />
                        </>
                    )}
                </div>
            </Card>
            <Card>
                <DataTable
                    className="p-datatable-sm p-datatable-striped"
                    rowHover
                    value={data}
                    loading={loading}
                    editMode="row"
                    dataKey="id"
                    onRowEditInit={onRowEditInit}
                    onRowEditCancel={onRowEditCancel}
                    onRowEditSave={onRowEditSave}
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Toplam Kayıt: {totalRecords}"
                    rows={20}
                    rowsPerPageOptions={[20, 50, 100]}
                >
                    <Column className="w-32" field="kind" header="MARKET" body={(item: IStorePreview) => renderBody(item, 'kind')} />
                    <Column className="w-40" field="city" header="İL" sortable body={(item: IStorePreview) => renderBody(item, 'city')} />
                    <Column className="w-44" field="town" header="İLÇE" sortable body={(item: IStorePreview) => renderBody(item, 'town')} />
                    <Column className="w-44" field="group" header="GRUP" editor={groupEditor} sortable body={(item: IStorePreview) => renderBody(item, 'group')} />
                    <Column field="name" header="ŞUBE ADI" sortable editor={nameEditor} body={(item: IStorePreview) => renderBody(item, 'name')} />
                    <Column field="address" header="ADRES" editor={addressEditor} body={(item: IStorePreview) => renderBody(item, 'address')} />
                    <Column rowEditor headerStyle={{ width: '7rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </Card>
        </div >
    )
}
