import React from 'react';
import PrimeReact from 'primereact/api';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import AppLayout from './components/AppLayout';
import LoginPage from './pages/LoginPage';
import { AuthWrapper, useAuth } from './contexts/auth.context';


PrimeReact.ripple = true;

function App() {
  return (
    <AuthWrapper>
      <BrowserRouter>
        <Switch>
          <Redirect path="/" exact to="/app" />
          <Route path="/login" exact component={LoginPage} />
          <RequiredAuth>
            <Route path="/app" component={AppLayout} />
          </RequiredAuth>
        </Switch>
      </BrowserRouter>
    </AuthWrapper>
  );
}

const RequiredAuth: React.FC = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.isAuthenticated) {
    return <Redirect to={{
      pathname: "/login",
      search: new URLSearchParams({ 'redirect': location.pathname }).toString(),
    }} />;
  }
  return <>{children}</>;
}

export default App;
