import Sidebar from "./Sidebar";


export default function Header() {
    return (
        <header className="h-16 bg-white shadow-lg w-full flex items-center space-x-5 text-white">
            <Sidebar side="horizontal" />
        </header>
    )
}
