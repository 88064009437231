import React from 'react'
import { Menu } from 'primereact/menu';
import { MenuItem } from "primereact/menuitem";
import { PrimeIcons } from 'primereact/api';
import { useHistory, useLocation } from "react-router-dom";
import { Menubar } from 'primereact/menubar';
import { ReactComponent as Logo } from '../assets/media/logo.svg';
import { useAuth } from '../contexts/auth.context';

interface SidebarProps {
    side: 'horizontal',
}

export default function Sidebar(props: SidebarProps) {

    if (props.side === 'horizontal') {
        return <HorizontalSidebar {...props} />
    }
    return <></>

}

function HorizontalSidebar({ }: SidebarProps) {
    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const items: MenuItem[] = [
        {
            label: 'Anasayfa',
            icon: PrimeIcons.MICROSOFT,
            command: () => history.push('/app'),
            className: location.pathname === '/app' ? 'active' : ''

        },
        {
            label: 'Ürünler',
            icon: PrimeIcons.LIST,
            command: () => history.push('/app/products'),
            className: location.pathname === '/app/products' ? 'active' : ''
        },
        {
            label: 'Marketler',
            icon: PrimeIcons.HOME,
            command: () => history.push('/app/stores'),
            className: location.pathname === '/app/stores' ? 'active' : ''

        },
    ];

    const rightItems: MenuItem[] = [
        {
            label: 'Çıkış Yap',
            icon: PrimeIcons.SIGN_OUT,
            command: () => {
                localStorage.removeItem('_auth');
                auth.signOut();
                history.push('/login');
            },
        },
    ]

    const [securityClick, setSecurityClick] = React.useState(0)

    React.useEffect(() => {
        if (securityClick === 7) {
            setSecurityClick(0);
            const value = prompt("Password");
            if (value === "18811923") {
                alert("API URL => " + process.env.REACT_APP_API_URL);
            }
        }
    }, [securityClick])

    return (
        <nav className="nav-menu flex w-full">
            <div className="flex items-center px-4 bg-primary w-60" onClick={() => setSecurityClick(securityClick + 1)}>
                <Logo fill="white" />
            </div>

            <div className="flex-1">
                {/* <Menu model={items} className="w-60 border-none py-0" /> */}
                <Menubar model={items} className="bg-white rounded-none border-none shadow-none" />
            </div>
            <div className="w-auto">
                {/* <Menu model={items} className="w-60 border-none py-0" /> */}
                <Menubar model={rightItems} className="bg-white rounded-none border-none shadow-none" />
            </div>
        </nav>
    )
}
