import React from 'react'
import { ICategoryPreview, IProductPreview, IProductStore } from '../services/api.service';
import { ProductList } from '../components/ProductsPage/ProductList';
import { ProductStoresEdit } from '../components/ProductsPage/ProductStoresEdit';
import { Toast } from 'primereact/toast';
import { Categories } from '../components/Categories';

export default function ProductsPage() {


    const [selectedProduct, setSelectedProduct] = React.useState<IProductPreview>()
    const [updatedStores, setUpdatedStores] = React.useState<IProductStore[]>([]);
    const [loading, setLoading] = React.useState(false)
    const [selectedCategory, setSelectedCategory] = React.useState<ICategoryPreview>()
    const [selectedCategoryNames, setSelectedCategoryNames] = React.useState<string[]>([]);

    const toast = React.useRef<Toast>(null);

    const onCategorySelected = (categoryTree: ICategoryPreview[], category: ICategoryPreview) => {
        setSelectedProduct(undefined);
        setSelectedCategoryNames(categoryTree.map(m => m.name));
        setSelectedCategory({ ...category });
    }

    const onSave = async (data: IProductStore[]) => {
        setUpdatedStores(data);
        if (toast) {

            toast.current?.show({ severity: 'success', summary: 'İşlem Başarılı', detail: 'Ürün market bilgileri güncellenmiştir.', life: 3000 });
        }
    }

    return (
        <div className="space-y-2">
            <Categories type="horizontal" loading={loading} onSelected={onCategorySelected} />
            <div className="">
                <Toast ref={toast} />
                <div className="flex gap-2">
                    <div className="w-5/12 space-y-5">
                        {selectedCategory && (
                            <ProductList category={selectedCategory} onSelected={setSelectedProduct} updatedStores={updatedStores} />
                        )}
                    </div>

                    <div className="flex-1">
                        {selectedProduct && (
                            <ProductStoresEdit
                                selectedCategories={selectedCategoryNames}
                                selectedProduct={selectedProduct}
                                onSave={onSave}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}