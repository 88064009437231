import Header from './Header'
import { Switch, Route } from "react-router-dom";
import DashboardPage from '../pages/DashboardPage';
import ProductsPage from '../pages/ProductsPage';
import StoresPage from '../pages/StoresPage';

export default function AppLayout() {
    return (
        <main className="flex h-full">
            {/* <Sidebar /> */}
            <section className="flex-1">
                <Header />
                <div className="p-2">
                    <Switch>
                        <Route path="/app" exact>
                            <DashboardPage />
                        </Route>
                        <Route path="/app/products" exact>
                            <ProductsPage />
                        </Route>
                        <Route path="/app/stores" exact>
                            <StoresPage />
                        </Route>
                    </Switch>
                </div>
            </section>
        </main>
    )
}
